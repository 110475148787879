export const getEnvironment = () => {
  const origin = window.location.origin;

  const environment = origin.includes("localhost")
    ? "local"
    : origin.includes("vdi-wsl")
    ? "local"
    : origin.includes("workspace.incorsoplatforms")
    ? "dev"
    : undefined;

  return environment;
};

export const isEnvironment = (environments) => {
  const environment = getEnvironment();
  return environments?.includes(environment?.toUpperCase());
};
